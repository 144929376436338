import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userUpdateContactReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
  userResetReducer,
  userForgotReducer,
  userReferralReducer,
} from './reducers/userReducers'

import {
  paymentListReducer,
  paymentDetailsReducer,
  paymentSubmissionCreateReducer,
  paymentRequestCreateReducer,
} from './reducers/paymentReducers'

import {
  referralListReducer,
} from './reducers/referralReducers'

const reducer = combineReducers({
  paymentList: paymentListReducer,
  paymentDetails: paymentDetailsReducer,
  paymentSubmissionCreate: paymentSubmissionCreateReducer,
  paymentRequestCreate: paymentRequestCreateReducer,

  referralList: referralListReducer,

  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userForgot: userForgotReducer,
  userReferral: userReferralReducer,
  userReset: userResetReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userUpdateContact: userUpdateContactReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
})

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
}

const middleware = [thunk]

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
