import React from 'react'
import logo from '../images/7.png'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../actions/userActions'
import logo2 from "../images/6.png";

const Header = () => {
    const dispatch = useDispatch()

    const logoutHandler = () => {
        dispatch(logout())
    }

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    return (
        <div>
            <div className="header">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12">
                            <div className="header-content">
                                <div className="header-left">
                                    {/* <div className="brand-logo"><a className="mini-logo" href="/"><img src={logo} alt="" width="40"/></a></div> */}
                                    <a className="mini-logo" href="/"><img src={logo} alt="" width="200" /></a>
                                    {/* <div className="search">
                                        <form action="#">
                                            <div className="input-group"><input type="text" className="form-control" placeholder="Search Here"/><span className="input-group-text"><i className="bi bi-search"></i></span></div>
                                        </form>
                                    </div> */}
                                </div>
                                <div className="header-right">
                                    <div className="dark-light-toggle"><span className="dark"><i className="ri-moon-line"></i></span><span className="light"><i className="ri-sun-line"></i></span></div>
                                    <div className="nav-item dropdown notification dropdown">
                                        {/* <div id="currency-swap" data-bs-toggle="dropdown" aria-expanded="false">
                                            <div className="notify-bell icon-menu"><span><i className="bi bi-piggy-bank"></i></span></div>
                                        </div>
                                        <ul className="dropdown-menu">
                                            <li><a className="dropdown-item" href="#">Switch Base Currency</a></li>
                                            <li><hr className="dropdown-divider" /></li>
                                            <li><a className="dropdown-item" href="#">Kenyan Shilling (KES)</a></li>
                                            <li><a className="dropdown-item" href="#">South African Rand (ZAR)</a></li>
                                            <li><a className="dropdown-item" href="#">Nigerian Naira (NGN)</a></li>
                                            <li><a className="dropdown-item" href="#">Botswana Pula (BWP)</a></li>

                                        </ul> */}
                                        <div tabIndex="-1" role="menu" aria-hidden="true" className="dropdown-menu notification-list dropdown-menu dropdown-menu-right">
                                            <h4>Recent Notification</h4>
                                            <div className="lists">
                                                <a className="" href="/#">
                                                    <div className="d-flex align-items-center">
                                                        <span className="me-3 icon success"><i className="ri-check-line"></i></span>
                                                        <div>
                                                            <p>Account created successfully</p>
                                                            <span>2020-11-04 12:00:23</span>
                                                        </div>
                                                    </div>
                                                </a>
                                                <a className="" href="/#">
                                                    <div className="d-flex align-items-center">
                                                        <span className="me-3 icon fail"><i className="ri-close-line"></i></span>
                                                        <div>
                                                            <p>2FA verification failed</p>
                                                            <span>2020-11-04 12:00:23</span>
                                                        </div>
                                                    </div>
                                                </a>
                                                <a className="" href="/#">
                                                    <div className="d-flex align-items-center">
                                                        <span className="me-3 icon success"><i className="ri-check-line"></i></span>
                                                        <div>
                                                            <p>Device confirmation completed</p>
                                                            <span>2020-11-04 12:00:23</span>
                                                        </div>
                                                    </div>
                                                </a>
                                                <a className="" href="/#">
                                                    <div className="d-flex align-items-center">
                                                        <span className="me-3 icon pending"><i className="ri-question-mark"></i></span>
                                                        <div>
                                                            <p>Phone verification pending</p>
                                                            <span>2020-11-04 12:00:23</span>
                                                        </div>
                                                    </div>
                                                </a>
                                                <a href="/notification.html">More<i className="ri-arrow-right-s-line"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dropdown profile_log dropdown">
                                        <div data-bs-toggle="dropdown" aria-haspopup="true" className="" aria-expanded="false">
                                            <div className="user icon-menu active"><span><i className="bi bi-person"></i></span></div>
                                        </div>
                                        <div tabIndex="-1" role="menu" aria-hidden="true" className="dropdown-menu dropdown-menu dropdown-menu-right">
                                            <div className="user-email">
                                                <div className="user">
                                                    <span className="thumb"><img src={logo2} alt="" /></span>
                                                    <div className="user-info">
                                                        <h5>{userInfo?.name}</h5>
                                                        <span>{userInfo?.email}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <a className="dropdown-item" href="/Settings"><span><i className="ri-user-line"></i></span>Profile</a> */}
                                            <a className="dropdown-item" href="/Balances"><span><i className="ri-wallet-line"></i></span>Balance</a>
                                            {/* <a className="dropdown-item" href="/Settings"><span><i className="ri-settings-3-line"></i></span>Settings</a> */}
                                            <a className="dropdown-item logout" href="/SignIn" onClick={logoutHandler}><i className="ri-logout-circle-line"></i>Logout</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header