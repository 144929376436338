import React from 'react';
import { ethers } from 'ethers'
import moment from 'moment'

import { useContext } from "react";
import { TransactionContext } from '../context/TransactionContext';

const Transaction = ({ allTxns }) => {
  const {
    currentAccount
} = useContext(TransactionContext);

  //console.log(allTxns)
  console.log(currentAccount)
  return (
    <div className='transaction-container'>
      {allTxns.length === 0 ? <div>

      </div> : <div className='grid-container'>

        {allTxns.map((txn, index) => {

          return (
            <div key={index} className='transactions'>
              <p>Receiver: {txn.to}</p>
              <p>Amount: {(txn.value).toString().substring(0, 6)} ZAR</p>
              <p>Date:  {moment.unix(txn.timeStamp.toString()).format('MM/DD/YYYY hh:mm')}</p>
              {txn.to.toString().toLowerCase() == currentAccount.toString() ? (
                <p><span className="badge px-3 py-2 bg-success">Received</span></p>
              ) : (
                <p><span className="badge px-3 py-2 bg-warning">Paid</span></p>
              )}
              
            </div>
          )
        })}
      </div>}
    </div>
  );
};

export default Transaction;