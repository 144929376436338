import React, { useEffect, useState } from "react";
import { ethers } from 'ethers'
import './Transactions.css'

import { useContext } from "react";
import { TransactionContext } from '../context/TransactionContext';
import Transaction from "../components/Transaction";

const Transactions = () => {
    const [isTxn, setIsTxn] = useState(false);
    const {
        transactions,
        connectWallet, 
        currentAccount
    } = useContext(TransactionContext);

    useEffect(() => {
        console.log(transactions)
        //getAllTransactions()
    }, [])

    return (
        <div>
            <div id="main-wrapper" className="show">


                <div className="content-body">
                    <div className="container">
                        <div className="page-title">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-xl-4">
                                    <div className="page-title-content">
                                        <h3>Transactions</h3>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="breadcrumbs">
                                        <a href="#">
                                            Dashboard
                                        </a>
                                        <span><i className="ri-arrow-right-s-line"></i></span><a href="#">Transactions</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {
                                currentAccount.length === 0 ? (
                                    <div>
                                        <div className="nav">
                                            <h1>SendFunds</h1>
                                        </div>
                                        <div className="content">
                                            <div>
                                                <p className="description">
                                                    Send <i className="fa-brands fa-ethereum"></i> to your friends
                                                    and family.
                                                </p>
                                                <button className="connect-btn" onClick={() => connectWallet()}>
                                                    Connect Wallet
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div>
                                            <div className="form">
                                                <div>
                                                <p>{currentAccount}</p>

                                                    {transactions.length === 0 ? (
                                                        <div></div>
                                                    ) : (
                                                        <div>
                                                            <Transaction allTxns={transactions} />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>


        </div>

    )
}

export default Transactions