import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { TransactionContext } from '../context/TransactionContext';
import { useDispatch, useSelector } from 'react-redux'
import {
    createPaymentRequest,
} from '../actions/paymentActions'
import { PAYMENT_CREATE_REQUEST_RESET } from '../constants/paymentConstants'
import { useNavigate } from 'react-router-dom';

const Request = () => {
    const [addressTo, setAddressTo] = useState('')
    const [amount, setAmount] = useState(0)
    const [message, setMessage] = useState('')
    const { currentAccount, connectWallet, isLoading, balance, updateBalance } = useContext(TransactionContext);

    const nav = useNavigate()
    const dispatch = useDispatch()

    const paymentRequestCreate = useSelector((state) => state.paymentRequestCreate)
    const {
        loading: loadingCreate,
        error: errorCreate,
        success: successCreate,
        paymentRequest: createdPaymentRequest,
    } = paymentRequestCreate

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    useEffect(() => {
        if (!userInfo) {
            nav("/SignIn")
        } else {
            dispatch({ type: PAYMENT_CREATE_REQUEST_RESET })
            console.log(userInfo)
        }
    }, [
        dispatch,
        userInfo,
        successCreate,
        createdPaymentRequest
    ])

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!addressTo || !amount || !message) {
            alert("Please fill all the fields");
        }
        else {
            dispatch(createPaymentRequest(addressTo, amount, message))
        }
    };

    return (
        <div>
            <div><div id="main-wrapper" className="show">

                <div className="content-body">
                    <div className="container">
                        <div className="page-title">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-xl-4">
                                    <div className="page-title-content">
                                        <h3>Request</h3>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="breadcrumbs">
                                        <a href="/">
                                            Home
                                        </a>
                                        <span><i className="ri-arrow-right-s-line"></i></span><a href="#">Request</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* {!currentAccount && (
                            <button onClick={connectWallet}>Connect Wallet</button>
                        )} */}

                        <div className="row justify-content-center h-100 align-items-center">
                            <div className="col-xl-6 col-md-6">
                                <div className="auth-form card">
                                    <div className="card-body">
                                        <p className="text-center mb-3">Request cash from a contact</p>
                                        <form onSubmit={handleSubmit} className="row g-3">
                                            <div className="col-12">
                                                <label className="form-label">To</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name=""
                                                    id=""
                                                    placeholder="Enter E-Mail Address"
                                                    required
                                                    value={addressTo}
                                                    onChange={(e) => setAddressTo(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-12">
                                                <label className="form-label">Message</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="message"
                                                    placeholder="Requesting payment"
                                                    value={message}
                                                    onChange={(e) => setMessage(e.target.value)}
                                                />
                                            </div>


                                            <div className="col-12">
                                                <label className="form-label">Amount</label>

                                                <input
                                                    className="form-control"
                                                    type="number"
                                                    name=""
                                                    id=""
                                                    placeholder="Enter Amount"
                                                    required
                                                    value={amount}
                                                    onChange={(e) => setAmount(e.target.value)}
                                                    step="any"
                                                    min="0"
                                                />
                                            </div>
                                            <div className="text-center mt-4">
                                                <button type="submit" className="btn btn-primary btn-block">Request </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div></div>
        </div>
    )
}

export default Request