import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { createReferral } from '../actions/referralActions'
import Loading from '../components/Loading';
import toast from 'react-hot-toast'
import delay from '../utils/delay';

const CreateReferral = () => {
    const [email, setEmail] = useState('')

    const userReferral = useSelector((state) => state.userReferral)
    const { loading, user, success, error } = userReferral

    const dispatch = useDispatch()
    const nav = useNavigate();

    useEffect(() => {
        if (success) {
            completeRequest();
        }
    }, [success])

const submitHandler = (e) => {
    e.preventDefault()
    dispatch(createReferral(email))
}

async function completeRequest() {
    toast.success("Sent Successfully!")
    await delay(3000)
    nav('/')
}

return (
    <div>
        <div id="main-wrapper" className="show">

            <div className="authincation section-padding">
                <div className="container h-100">
                    <div className="row justify-content-center h-100 align-items-center">
                        <div className="col-xl-4 col-md-5">
                            <div className="mini-logo text-center my-3">
                                <h4 className="card-title mt-5">Create Referral</h4>
                            </div>
                            <div className="auth-form card">
                                <div className="card-body">
                                    {error && <h5 className="text-brand-2 mt-30">{error}</h5>}
                                    {loading && <h5 className="text-brand-2 mt-30"><Loading /></h5>}
                                    <form onSubmit={submitHandler} className="row g-3">
                                        <div className="col-12">
                                            <label className="form-label">Email</label>

                                            <input type="text" className="form-control" placeholder='user@email.com' onChange={(e) => setEmail(e.target.value)} />
                                        </div>
                                        <div className="text-center mt-4">
                                            <button type="submit" className="btn btn-primary btn-block">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
)
}

export default CreateReferral