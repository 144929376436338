import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { forgot } from '../actions/userActions'
import { listReferrals } from '../actions/referralActions'
import logo from '../images/6.png'
import Loading from '../components/Loading';
import moment from 'moment'

const Referrals = () => {
    const [email, setEmail] = useState('')

    const dispatch = useDispatch()
    const nav = useNavigate();

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const referralList = useSelector((state) => state.referralList)
    const { loading, error, referrals } = referralList

    useEffect(() => {
        if (userInfo) {
            dispatch(listReferrals(userInfo._id))
        } else {
            nav('/signin')
        }
    }, [dispatch, userInfo])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(forgot(email))
    }

    return (
        <div>
            <div id="main-wrapper">

                <div className="content-body" style={{ minHeight: `1015px` }}>
                    <div className="container">
                        <div className="page-title">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-xl-4">
                                    <div className="page-title-content">
                                        <h3>Referrals</h3>
                                        <p className="mb-2">Referrals</p>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="breadcrumbs"><a href="#">Home </a><span><i className="ri-arrow-right-s-line"></i></span><a href="#">Referrals</a></div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-3 col-sm-6">
                                <div className="stat-widget d-flex align-items-center bg-white">
                                    <div className="widget-icon me-3 bg-primary"><span><i className="ri-file-copy-2-line"></i></span></div>
                                    <div className="widget-content">
                                        <h3 className='text-success'>R {referrals?.filter(x => x.referralSucceeded == true).length * 10}</h3>
                                        <p>Total Earnings</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6">
                                <div className="stat-widget d-flex align-items-center bg-white">
                                    <div className="widget-icon me-3 bg-primary"><span><i className="ri-file-list-3-line"></i></span></div>
                                    <div className="widget-content">
                                        <h3>{referrals?.length}</h3>
                                        <p>Total Referrals</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6">
                                <div className="stat-widget d-flex align-items-center bg-white">
                                    <div className="widget-icon me-3 bg-primary"><span><i className="ri-file-paper-line"></i></span></div>
                                    <div className="widget-content">
                                        <h3>{referrals?.filter(x => x.referralSucceeded == false).length}</h3>
                                        <p>Pending Referrals</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6">
                                <div className="stat-widget d-flex align-items-center bg-white">
                                    <div className="widget-icon me-3 bg-primary"><span><i className="ri-file-paper-2-line"></i></span></div>
                                    <div className="widget-content">
                                        <h3>{referrals?.filter(x => x.referralSucceeded == true).length}</h3>
                                        <p>Fulfilled Referrals</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-header flex-row">
                                        <h4 className="card-title">Your Referrals </h4>
                                        <a className="btn btn-primary" href="/CreateReferral"><span><i className="bi bi-plus"></i></span>Create Referral</a>
                                    </div>
                                    <div className="card-body">
                                        <div className="invoice-table">
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Client</th>
                                                            <th>Date Created</th>
                                                            <th>Potential Earnings</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {referrals?.map((referral) => (
                                                            <tr key={referral._id}>
                                                                <td>{referral.receiverEmail}</td>
                                                                <td>{moment(referral.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</td>
                                                                <td>R10</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Referrals