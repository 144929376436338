import React from 'react'
import logo from "../images/6.png";


const EmailVerified = () => {
    return (
        <div>
            <div className="verification section-padding">
                <div className="container h-100">
                    <div className="row justify-content-center h-100 align-items-center">
                        <div className="col-xl-5 col-md-6">
                            <div className="mini-logo text-center my-4">
                                <img className="signin-logo" src={logo} alt="" />
                                <h4 className="card-title mt-5">E-Mail Verified</h4>
                            </div>
                            <div className="auth-form card">
                                <div className="card-body">
                                    <form action="verify-step-2.html" className="identity-upload">
                                        <div className="identity-content">
                                            <span className="icon"><i className="ri-mail-check-line"></i></span>
                                            <p>E-Mail address has been verified. <br />Your account is now active!</p>
                                            <a href="/signin">Proceed to sign in</a>
                                        </div>
                                    </form>
                                </div>
                                {/* <div className="card-footer text-center">
                            <a href="signup.html">Email didn't arrive?</a>
                        </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmailVerified