import React from 'react'
// import { useMoralis } from "react-moralis";
import { useNavigate } from "react-router-dom";
import logo from "../images/dashpop.png";
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../actions/userActions'

const Sidebar = () => {
    const dispatch = useDispatch()

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const logoutHandler = () => {
        dispatch(logout())
    }

    return (
        <div>
            <div className="sidebar">
                <div className="brand-logo"><a className="full-logo" href="/"><img src={logo} alt="" width="30" /></a></div>
                <div className="menu active">
                    <ul className="show">
                        {/* <li className="active"><a href="/" className="active">
                            <span><i className="bi bi-house"></i></span>
                            <span className="nav-text">Dashboard</span>
                        </a>
                        </li> */}

                        <li>
                            <a href="/balances">
                                <span><i className="ri-wallet-line"></i></span>
                                <span className="nav-text">Wallet</span>
                            </a>
                        </li>

                        {userInfo?.isAdmin ? (
                            <li><a href="/Referrals">
                                <span><i className="ri-user-add-line"></i></span>
                                <span className="nav-text">Referrals</span>
                            </a>
                            </li>
                        ) : (<></>)
                        }


                        {/* <li><a href="/BuyCrypto">
                            <span><i className="bi bi-shop-window"></i></span>
                            <span className="nav-text">Buy Crypto</span>
                        </a>
                        </li> */}

                        {/* {userInfo?.isAdmin ? (
                            <li><a href="/send">
                                <span><i className="bi bi-send-plus"></i></span>
                                <span className="nav-text">Send</span>
                            </a>
                            </li>
                        ) : (<></>)
                        } */}

                        {/* <li><a href="/contacts">
                            <span><i className="ri-file-copy-2-line"></i></span>
                            <span className="nav-text">Contacts</span>
                        </a>
                        </li> */}

                        {/* <li><a href="/portfolio">
                            <span><i className="bi bi-safe"></i></span>
                            <span className="nav-text">Portfolio</span>
                        </a>
                        </li> */}
                        {/* <li><a href="/invoice.html">
                            <span><i className="bi bi-receipt-cutoff"></i></span>
                            <span className="nav-text">Invoice</span>
                        </a>
                        </li> */}
                        {/* <li><a href="/bridge">
                            <span><i className="bi bi-signpost-split"></i></span>
                            <span className="nav-text">Bridge</span>
                        </a>
                        </li> */}
                        {/* <li><a href="/settings">
                            <span><i className="bi bi-gear"></i></span>
                            <span className="nav-text">Settings</span>
                        </a>
                        </li> */}

                        <li className="logout"><a href="/SignIn" onClick={logoutHandler}>
                            <span><i className="bi bi-box-arrow-left"></i></span>
                            <span className="nav-text">Signout</span>
                        </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Sidebar