import React from 'react'
import moment from 'moment'
import { useContext } from "react";
import { TransactionContext } from '../context/TransactionContext';
import logo from "../images/6.png";

const TransactionDetails = () => {
    const { currentAccount, connectWallet, isLoading, balance, transactions } = useContext(TransactionContext);

    return (
        <>
            <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Transaction History</h4>
                        {/* <a href="/Transactions">See more</a> */}
                    </div>
                    <div className="card-body">
                        <div className="invoice-content ps ps--active-y">
                            <ul>
                                {transactions?.map((transaction, index) => (
                                    <li key={index} className="d-flex justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <div className="invoice-user-img me-3"><img src={logo ? logo : "https://www.nicepng.com/png/detail/208-2087666_payroll-services-icon-compensation-icon-png.png"} alt="" width="50" /></div>
                                            <div className="invoice-info">
                                                <h5 className="mb-0">ZARGO</h5>
                                                <p>{moment.unix(transaction.timeStamp.toString()).format('MM/DD/YYYY hh:mm')}</p>
                                            </div>
                                        </div>
                                        <div className="text-end">
                                            <h5 className="mb-2">R { transaction.value }</h5>
                                            {transaction.to.toLowerCase() == currentAccount.toLowerCase() ? (
                                                <p><span className="badge px-3 py-2 bg-success">Received</span></p>
                                            ) : (
                                                <p><span className="badge px-3 py-2 bg-warning">Transact</span></p>
                                            )}
                                        </div>
                                    </li>
                                ))}

                            </ul>
                            <div className="ps__rail-x"><div className="ps__thumb-x" tabIndex="0"></div></div><div className="ps__rail-y"><div className="ps__thumb-y" tabIndex="0"></div></div></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TransactionDetails