import {
    PAYMENT_LIST_REQUEST,
    PAYMENT_LIST_SUCCESS,
    PAYMENT_LIST_FAIL,
    PAYMENT_DETAILS_REQUEST,
    PAYMENT_DETAILS_SUCCESS,
    PAYMENT_DETAILS_FAIL,
    PAYMENT_CREATE_SUBMISSION_RESET,
    PAYMENT_CREATE_SUBMISSION_FAIL,
    PAYMENT_CREATE_SUBMISSION_SUCCESS,
    PAYMENT_CREATE_SUBMISSION_REQUEST,
    PAYMENT_CREATE_REQUEST_RESET,
    PAYMENT_CREATE_REQUEST_FAIL,
    PAYMENT_CREATE_REQUEST_SUCCESS,
    PAYMENT_CREATE_REQUEST_REQUEST,
  } from '../constants/paymentConstants'
  
  export const paymentListReducer = (state = { payments: [] }, action) => {
    switch (action.type) {
      case PAYMENT_LIST_REQUEST:
        return { loading: true, payments: [] }
      case PAYMENT_LIST_SUCCESS:
        return {
          loading: false,
          payments: action.payload.payments,
          pages: action.payload.pages,
          page: action.payload.page,
        }
      case PAYMENT_LIST_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }
  
  export const paymentDetailsReducer = (
    state = { payment: { } },
    action
  ) => {
    switch (action.type) {
      case PAYMENT_DETAILS_REQUEST:
        return { ...state, loading: true }
      case PAYMENT_DETAILS_SUCCESS:
        return { loading: false, payment: action.payload }
      case PAYMENT_DETAILS_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }
  
  export const paymentSubmissionCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case PAYMENT_CREATE_SUBMISSION_REQUEST:
        return { loading: true }
      case PAYMENT_CREATE_SUBMISSION_SUCCESS:
        return { loading: false, success: true, paymentSubmission: action.payload }
      case PAYMENT_CREATE_SUBMISSION_FAIL:
        return { loading: false, error: action.payload }
      case PAYMENT_CREATE_SUBMISSION_RESET:
        return {}
      default:
        return state
    }
  }

  export const paymentRequestCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case PAYMENT_CREATE_REQUEST_REQUEST:
        return { loading: true }
      case PAYMENT_CREATE_REQUEST_SUCCESS:
        return { loading: false, success: true, paymentRequest: action.payload }
      case PAYMENT_CREATE_REQUEST_FAIL:
        return { loading: false, error: action.payload }
      case PAYMENT_CREATE_REQUEST_RESET:
        return {}
      default:
        return state
    }
  }
  
  
  
 
  