import {
  REFERRAL_LIST_REQUEST,
  REFERRAL_LIST_SUCCESS,
  REFERRAL_LIST_FAIL,
  REFERRAL_LIST_RESET,
} from "../constants/referralConstants"

export const referralListReducer = (state = { referrals: [] }, action) => {
  switch (action.type) {
    case REFERRAL_LIST_REQUEST:
      return { loading: true }
    case REFERRAL_LIST_SUCCESS:
      return { loading: false, referrals: action.payload }
    case REFERRAL_LIST_FAIL:
      return { loading: false, error: action.payload }
    case REFERRAL_LIST_RESET:
      return { referalls: [] }
    default:
      return state
  }
}