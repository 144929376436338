import axios from 'axios'
import {
    PAYMENT_LIST_REQUEST,
    PAYMENT_LIST_SUCCESS,
    PAYMENT_LIST_FAIL,
    PAYMENT_DETAILS_REQUEST,
    PAYMENT_DETAILS_SUCCESS,
    PAYMENT_DETAILS_FAIL,
    PAYMENT_CREATE_SUBMISSION_REQUEST,
    PAYMENT_CREATE_SUBMISSION_SUCCESS,
    PAYMENT_CREATE_SUBMISSION_FAIL,
    PAYMENT_CREATE_REQUEST_REQUEST,
    PAYMENT_CREATE_REQUEST_SUCCESS,
    PAYMENT_CREATE_REQUEST_FAIL,
} from '../constants/paymentConstants'
import { logout } from './userActions'

export const listPayments = (keyword = '', pageNumber = '') => async (
    dispatch
) => {
    try {
        dispatch({ type: PAYMENT_LIST_REQUEST })

        const { data } = await axios.get(
            `/api/payments?keyword=${keyword}&pageNumber=${pageNumber}`
        )

        dispatch({
            type: PAYMENT_LIST_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: PAYMENT_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const listPaymentDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: PAYMENT_DETAILS_REQUEST })

        const { data } = await axios.get(`/api/payments/${id}`)

        dispatch({
            type: PAYMENT_DETAILS_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: PAYMENT_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}


export const createPaymentSubmission = (addressTo, amount, message) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PAYMENT_CREATE_SUBMISSION_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.post(`/api/payments/submission`, { addressTo, amount, message }, config)

        dispatch({
            type: PAYMENT_CREATE_SUBMISSION_SUCCESS,
            payload: data,
        })
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        if (message === 'Not authorized, token failed') {
            dispatch(logout())
        }
        dispatch({
            type: PAYMENT_CREATE_SUBMISSION_FAIL,
            payload: message,
        })
    }
}

export const createPaymentRequest = (addressTo, amount, message) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PAYMENT_CREATE_REQUEST_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.post(`/api/payments/request`, { addressTo, amount, message }, config)

        dispatch({
            type: PAYMENT_CREATE_REQUEST_SUCCESS,
            payload: data,
        })
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        if (message === 'Not authorized, token failed') {
            dispatch(logout())
        }
        dispatch({
            type: PAYMENT_CREATE_REQUEST_FAIL,
            payload: message,
        })
    }
}