import axios from "axios"
import {
    USER_REFERRAL_REQUEST,
    USER_REFERRAL_SUCCESS,
    USER_REFERRAL_FAIL,
} from '../constants/userConstants'

import {
    REFERRAL_LIST_FAIL,
    REFERRAL_LIST_SUCCESS,
    REFERRAL_LIST_REQUEST,
    REFERRAL_LIST_RESET,
} from '../constants/referralConstants'

export const listReferrals = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: REFERRAL_LIST_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.get(`/api/referrals/${id}`, config)

        dispatch({
            type: REFERRAL_LIST_SUCCESS,
            payload: data,
        })
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        dispatch({
            type: REFERRAL_LIST_FAIL,
            payload: message,
        })
    }
}

export const createReferral = (email) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_REFERRAL_REQUEST,
        })

        const {
            userLogin: { userInfo },
          } = getState()
      
          const config = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userInfo.token}`,
            },
          }

        const { data } = await axios.post(
            '/api/referrals/create',
            { email },
            config
        )

        dispatch({
            type: USER_REFERRAL_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: USER_REFERRAL_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}