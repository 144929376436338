import React from 'react'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
        }
    }
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

const chartData = Math.floor(Math.random() * labels.length)

export const data = {
    labels,
    datasets: [
        {
            fill: true,
            label: "None",
            // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            data: [1800, 2100, 1500, 1100, 1800, 2000, 2300],
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
    ],
};

const Portfolio = () => {
    return (
        <div>
            <div><div id="main-wrapper" className="show">
                <Header />
                <Sidebar />

                <div className="content-body">
                    <div className="container">
                        <div className="page-title">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-xl-4">
                                    <div className="page-title-content">
                                        <h3>Portfolio</h3>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="breadcrumbs">
                                        <a href="/">
                                            Home
                                        </a>
                                        <span><i className="ri-arrow-right-s-line"></i></span><a href="#">Portfolio</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-6 col-lg-6">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Stats</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                <div className="stat-widget d-flex align-items-center">
                                                    <div className="widget-icon me-3 bg-primary"><span><i className="ri-wallet-line"></i></span></div>
                                                    <div className="widget-content">
                                                        <h3>- 15 %</h3>
                                                        <p>Last Month</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                <div className="stat-widget d-flex align-items-center">
                                                    <div className="widget-icon me-3 bg-secondary"><span><i className="ri-wallet-2-line"></i></span></div>
                                                    <div className="widget-content">
                                                        <h3>- 45 %</h3>
                                                        <p>Last 3 Months</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                <div className="stat-widget d-flex align-items-center">
                                                    <div className="widget-icon me-3 bg-success"><span><i className="ri-wallet-3-line"></i></span></div>
                                                    <div className="widget-content">
                                                        <h3>250 %</h3>
                                                        <p>Last Year</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                <div className="stat-widget d-flex align-items-center">
                                                    <div className="widget-icon me-3 bg-danger"><span><i className="ri-wallet-3-line"></i></span></div>
                                                    <div className="widget-content">
                                                        <h3>2225 %</h3>
                                                        <p>Last 5 Years</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6">
                                <div id="user-activity" className="card" data-aos="fade-up">
                                    <div className="card-header">
                                        <h4 className="card-title">Investments</h4>
                                    </div>
                                    <div className="card-body">
                                        <Line options={options} data={data} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Assets</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="payments-content">
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Asset</th>
                                                            <th>Price</th>
                                                            <th>Balance</th>
                                                            <th>Value</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td><img src={"https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png"} alt="" width="22" className="me-3 img-fluid" />Polygon</td>
                                                            <td>R 15.35</td>
                                                            <td>42.34 MATIC</td>
                                                            <td><span className="badge px-3 py-2 bg-success">R650</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><img src={"https://s2.coinmarketcap.com/static/img/coins/64x64/4687.png"} alt="" width="22" className="me-3 img-fluid" />Binance USD</td>
                                                            <td>R 17.16</td>
                                                            <td>29.137 BUSD</td>
                                                            <td><span className="badge px-3 py-2 bg-success">R 500</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><img src={"https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png"} alt="" width="22" className="me-3 img-fluid" />Ethereum</td>
                                                            <td>R 26254</td>
                                                            <td>0.15 ETH</td>
                                                            <td><span className="badge px-3 py-2 bg-success">R1500</span></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div></div>
        </div>
    )
}

export default Portfolio