import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { TransactionContext } from '../context/TransactionContext';
import { ethers } from "ethers";
import TokenArtifact from "../utils/Token.json";
import contractAddress from "../utils/contract-address.json";
import { useDispatch, useSelector } from 'react-redux'
import {
    createPaymentSubmission,
} from '../actions/paymentActions'
import { PAYMENT_CREATE_SUBMISSION_RESET } from '../constants/paymentConstants'
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast'
import delay from '../utils/delay';

const { ethereum } = window;

const Send = () => {
    const [txnHash, setTxnHash] = useState('');
    const [addressTo, setAddressTo] = useState('')
    const [amount, setAmount] = useState(0)
    const [message, setMessage] = useState('')
    const [toastId, setToastId] = useState('')
    const { currentAccount, connectWallet, isLoading, balance, updateBalance } = useContext(TransactionContext);

    const nav = useNavigate()
    const dispatch = useDispatch()

    const paymentSubmissionCreate = useSelector((state) => state.paymentSubmissionCreate)
    const {
        loading: loadingCreate,
        error: errorCreate,
        success: successCreate,
        paymentSubmission: createdPaymentSubmission,
    } = paymentSubmissionCreate

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    useEffect(() => {
        if (successCreate) {
            completeRequest()
        }
        if (!userInfo) {
            nav("/SignIn")
        } else {
            dispatch({ type: PAYMENT_CREATE_SUBMISSION_RESET })
        }
    }, [
        dispatch,
        userInfo,
        successCreate,
        createdPaymentSubmission
    ])

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!addressTo || !amount || !message) {
            alert("Please fill all the fields");
        }
        else {

            setToastId(toast.loading('Printing your receipt, an email confirmation will be sent to your inbox 📫'))

            const provider = new ethers.providers.JsonRpcProvider('https://data-seed-prebsc-1-s1.binance.org:8545')
            dispatch(createPaymentSubmission(addressTo, amount, message))

            //completeRequest()
        }
    };

    async function completeRequest() {
        toast.dismiss(toastId)
        toast.success("Sent Successfully!")
        await delay(5000)
        nav('/')
    }

    return (
        <div>
            <div><div id="main-wrapper" className="show">

                <div className="content-body">
                    <div className="container">
                        <div className="page-title">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-xl-4">
                                    <div className="page-title-content">
                                        <h3>Send</h3>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="breadcrumbs">
                                        <a href="/">
                                            Home
                                        </a>
                                        <span><i className="ri-arrow-right-s-line"></i></span><a href="#">Send</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* {!currentAccount && (
                            <button onClick={connectWallet}>Connect Wallet</button>
                        )} */}

                        <div className="row justify-content-center h-100 align-items-center">
                            <div className="col-xl-6 col-md-6">
                                <div className="auth-form card">
                                    <div className="card-body">
                                        <p className="text-center mb-3">Send your tokens to a contact</p>
                                        <form onSubmit={handleSubmit} className="row g-3">
                                            <div className="col-12">
                                                <label className="form-label">Email address - From - {userInfo.email}</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name=""
                                                    id=""
                                                    placeholder="Enter Email Address"
                                                    required
                                                    value={addressTo}
                                                    onChange={(e) => setAddressTo(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-12">
                                                <label className="form-label">Message</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="message"
                                                    placeholder="Thank you for your product/service"
                                                    value={message}
                                                    onChange={(e) => setMessage(e.target.value)}
                                                />
                                            </div>


                                            <div className="col-12">
                                                <label className="form-label">Amount - Balance - R {balance}</label>

                                                <input
                                                    className="form-control"
                                                    type="number"
                                                    name=""
                                                    id=""
                                                    placeholder="Enter Amount"
                                                    required
                                                    value={amount}
                                                    onChange={(e) => setAmount(e.target.value)}
                                                    step="any"
                                                    min="0"
                                                />
                                            </div>
                                            <div className="text-center mt-4">
                                                <button type="submit" disabled={loadingCreate} className="btn btn-primary btn-block">Send </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div></div>
        </div>
    )
}

export default Send