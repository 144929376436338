export const PAYMENT_LIST_REQUEST = 'PAYMENT_LIST_REQUEST'
export const PAYMENT_LIST_SUCCESS = 'PAYMENT_LIST_SUCCESS'
export const PAYMENT_LIST_FAIL = 'PAYMENT_LIST_FAIL'

export const PAYMENT_DETAILS_REQUEST = 'PAYMENT_DETAILS_REQUEST'
export const PAYMENT_DETAILS_SUCCESS = 'PAYMENT_DETAILS_SUCCESS'
export const PAYMENT_DETAILS_FAIL = 'PAYMENT_DETAILS_FAIL'

export const PAYMENT_CREATE_SUBMISSION_REQUEST = 'PAYMENT_CREATE_SUBMISSION_REQUEST'
export const PAYMENT_CREATE_SUBMISSION_SUCCESS = 'PAYMENT_CREATE_SUBMISSION_SUCCESS'
export const PAYMENT_CREATE_SUBMISSION_FAIL = 'PAYMENT_CREATE_SUBMISSION_FAIL'
export const PAYMENT_CREATE_SUBMISSION_RESET = 'PAYMENT_CREATE_SUBMISSION_RESET'

export const PAYMENT_CREATE_REQUEST_REQUEST = 'PAYMENT_CREATE_REQUEST_REQUEST'
export const PAYMENT_CREATE_REQUEST_SUCCESS = 'PAYMENT_CREATE_REQUEST_SUCCESS'
export const PAYMENT_CREATE_REQUEST_FAIL = 'PAYMENT_CREATE_REQUEST_FAIL'
export const PAYMENT_CREATE_REQUEST_RESET = 'PAYMENT_CREATE_REQUEST_RESET'