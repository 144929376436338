import './App.css';
import './perfect-scrollbar.css'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import NoPage from './pages/NoPage';
import Dashboard from './pages/Dashboard';
import Settings from './pages/Settings';
import Portfolio from './pages/Portfolio';
import Bridge from './pages/Bridge';
import Send from './pages/Send';
import Transactions from './pages/Transactions';
import Layout from './components/Layout';
import Balances from './pages/Balances';
import Contacts from './pages/Contacts';
import Request from './pages/Request';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import VerificationEmailSent from './pages/VerificationEmailSent';
import { Toaster } from 'react-hot-toast';
import EmailVerified from './pages/EmailVerified';
import BuyCrypto from './pages/BuyCrypto';
import WelcomeVerificationEmailSent from './pages/WelcomeVerificationEmailSent';
import Referrals from './pages/Referrals';
import CreateReferral from './pages/CreateReferral';


function App() {
  return (
    <>
      <Toaster />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Dashboard />} />
            <Route exact path="/Send" element={<Send />} />
            <Route exact path="/Request" element={<Request />} />
            <Route exact path="/Settings" element={<Settings />} />
            <Route exact path="/Contacts" element={<Contacts />} />
            <Route exact path="/Portfolio" element={<Portfolio />} />
            <Route exact path="/Transactions" element={<Transactions />} />
            <Route exact path="/CreateReferral" element={<CreateReferral />} />
            <Route exact path="/Referrals" element={<Referrals />} />
            <Route exact path="/Bridge" element={<Bridge />} />
            <Route exact path="/Balances" element={<Balances />} />
            <Route path="*" element={<NoPage />} />
          </Route>
          <Route exact path="/SignIn" element={<SignIn />} />
          <Route exact path="/SignUp" element={<SignUp />} />
          <Route exact path="/ForgotPassword" element={<ForgotPassword />} />
          <Route exact path="/VerificationEmailSent/:email" element={<VerificationEmailSent />} />
          <Route exact path="/Welcome/:email" element={<WelcomeVerificationEmailSent />} />
          <Route exact path="/Reset-Password/:id/:token" element={<ResetPassword />} />
          <Route exact path="/Verify/:id/:token" element={<EmailVerified />} />
          <Route exact path="/BuyCrypto" element={<BuyCrypto/>}/>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
