import React from 'react'
import { useContext } from "react";
import { TransactionContext } from '../context/TransactionContext';

const BalanceDetails = () => {
    const { currentAccount, connectWallet, isLoading, balance, transactions } = useContext(TransactionContext);

    //console.log(currentAccount)

    return (
        <>
            <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Balance Details</h4>
                        {/* <a>{"QuickCode: " + currentAccount.substring(currentAccount.length - 3)}</a> */}
                        {/* <a>{currentAccount}</a> */}
                        {/* <a href="/Request">Create Payment Request</a> */}
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="total-balance">
                                    <p>Total Balance</p>
                                    <h2>R {balance}</h2>
                                </div>
                            </div>
                            {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                <div className="balance-stats">
                                    <p>Last Month</p>
                                    <h3>BNB 0</h3>
                                </div>
                            </div> */}
                            {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                <div className="balance-stats">
                                    <p>Expenses</p>
                                    <h3>BNB 0</h3>
                                </div>
                            </div> */}
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                <div className="balance-stats active">
                                    <p>Received</p>
                                    <h3>R {balance}</h3>
                                </div>
                            </div>
                            {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                <div className="balance-stats">
                                    <p>Sent</p>
                                    <h3>R 0.0</h3>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BalanceDetails