import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../actions/userActions'
import logo from "../images/6.png";
import toast from 'react-hot-toast'
import delay from '../utils/delay';

const SignIn = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const nav = useNavigate();

    const dispatch = useDispatch()

    const userLogin = useSelector((state) => state.userLogin)
    const { success, loading, error, userInfo } = userLogin

    //const redirect = location.search ? location.search.split('=')[1] : '/'

    useEffect(() => {
        if (success) {
            completeRequest()
        }
    }, [success])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(login(email, password))
    }

    async function completeRequest() {
        if (userInfo.address == null) {
            await delay(3000)
            //console.log(userInfo)
            nav(`/VerificationEmailSent/${userInfo.email}`)
        }
        else if (userInfo) {
            toast.success("Welcome, " + userInfo.name + " - Sign In Successful!")
            await delay(3000)
            nav('/')
        } 

    }

    return (
        <div>
            <div style={{ marginTop: `25px` }} className="authincation section-padding">
                <div className="container h-100">
                    <div className="row justify-content-center h-100 align-items-center">
                        <div className="col-xl-5 col-md-6">
                            <div className="mini-logo text-center my-4">
                                <img className="signin-logo" src={logo} alt="" />
                                <h4 className="card-title">Sign in to Zargo</h4>
                            </div>
                            <div className="auth-form card">
                                <div className="card-body">
                                    {error && <h5 className="text-brand-2 mx-30">{error}</h5>}
                                    <form onSubmit={submitHandler} name="myform" className="signin_validate row g-3">
                                        <div className="col-12">
                                            <label className="form-label">Email</label>
                                            <input type="email" className="form-control" onChange={(e) => setEmail(e.target.value)}
                                                placeholder="user@mail.com" name="email" />
                                        </div>
                                        <div className="col-12">
                                            <label className="form-label">Password</label>
                                            <input type="password" className="form-control" onChange={(e) => setPassword(e.target.value)}
                                                placeholder="************" name="password" />
                                        </div>
                                        <div className="col-6">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Remember
                                                    me</label>
                                            </div>
                                        </div>
                                        <div className="col-6 text-end">
                                            <a href="/ForgotPassword">Forgot Password?</a>
                                        </div>
                                        <div className="d-grid gap-2">
                                            <button type="submit" className="btn btn-primary">Sign in</button>
                                        </div>
                                    </form>
                                    {/* <div className="d-grid gap-2 mt-3">
                                        <button className="btn btn-metamask text-dark">Sign in with Metamask</button>
                                    </div> */}
                                    <p className="mt-3 mb-0">Don't have an account? <a className="text-primary" href="/signup">Sign
                                        up</a></p>
                                </div>

                            </div>
                            {/* <div className="privacy-link">
                                <a href="/signup">Have an issue with 2-factor
                                    authentication?</a>
                                <br />
                                <a href="/signup">Privacy Policy</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignIn