import React from 'react'
import logo from "../images/6.png";
import { useParams } from 'react-router-dom';

const VerificationEmailSent = () => {
    const params = useParams(); 
    return (
        <div>
            <div className="verification section-padding">
                <div className="container h-100">
                    <div className="row justify-content-center h-100 align-items-center">
                        <div className="col-xl-5 col-md-6">
                            <div className="mini-logo text-center my-4">
                                <img className="signin-logo" src={logo} alt="" />
                                <h4 className="card-title mt-5">Verification Email</h4>
                            </div>
                            <div className="auth-form card">
                                <div className="card-body">
                                    <form action="verify-step-2.html" className="identity-upload">
                                        <div className="identity-content">
                                            <span className="icon"><i className="ri-mail-check-line"></i></span>
                                            <h4>{params.email}</h4>
                                            <p>We sent a verification email to you! Check your inbox</p>
                                            <a href="https://mail.google.com">Go to mail inbox</a>
                                            <br/>
                                            <a href="/signin">Go to Sign In</a>
                                        </div>
                                    </form>
                                </div>
                                <div className="card-footer text-center">
                                    <a href="/ForgotPassword">Resend Email? You will need to reset your password to receive a new mail</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VerificationEmailSent