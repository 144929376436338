import React from 'react'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import logo from '../images/12.png'
// import { SwapWidget } from '@uniswap/widgets'
// import '@uniswap/widgets/fonts.css'

const Bridge = () => {
    // You can also pass a token list as JSON, as long as it matches the schema
    const MY_TOKEN_LIST = [
        {
            "name": "Dai Stablecoin",
            "address": "0x6B175474E89094C44Da98b954EedeAC495271d0F",
            "symbol": "DAI",
            "decimals": 18,
            "chainId": 1,
            "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png"
        },
        {
            "name": "Tether USD",
            "address": "0xdAC17F958D2ee523a2206206994597C13D831ec7",
            "symbol": "USDT",
            "decimals": 6,
            "chainId": 1,
            "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png"
        },
        {
            "name": "ZARGO",
            "address": "0x953cA25aaa04158Ea9d580a379CFa6AF3eDBa14B",
            "symbol": "ZARG",
            "decimals": 18,
            "chainId": 5,
            "logoURI": "https://raw.githubusercontent.com/BaseSpaceCode/dashpop/main/frontend/src/images/3.png?token=GHSAT0AAAAAAB2SK7PWNZYDRUMIGBDLPGFQY6G6HVQ"
        },
    ]

    return (
        <div>
            <div><div id="main-wrapper" className="show">
                <Header />
                <Sidebar />

                <div className="content-body">
                    <div className="container">
                        <div className="page-title">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-xl-4">
                                    <div className="page-title-content">
                                        <h3>Bridge</h3>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="breadcrumbs">
                                        <a href="/">
                                            Home
                                        </a>
                                        <span><i className="ri-arrow-right-s-line"></i></span><a href="#">Bridge</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center h-100 align-items-center">
                            <div className="col-xl-5 col-md-6">
                                <div className="Uniswap">
                                    {/* <SwapWidget tokenList={MY_TOKEN_LIST} /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div></div>
        </div>
    )
}

export default Bridge