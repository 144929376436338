import React, { useState, useEffect } from 'react'
import { register } from '../actions/userActions'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { ethers } from 'ethers';
import logo from "../images/6.png";
import Loading from '../components/Loading'
import toast from 'react-hot-toast'
import delay from '../utils/delay';

const SignUp = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage] = useState(null)
    const nav = useNavigate();

    const dispatch = useDispatch()


    const userRegister = useSelector((state) => state.userRegister)
    const { loading, success, error, userInfo } = userRegister

    useEffect(() => {
        if (userInfo?.email != null) {
            completeRequest()
        }
    }, [success, userInfo])

    const submitHandler = (e) => {
        e.preventDefault()
        if (password !== confirmPassword) {
            setMessage('Passwords do not match')
        } else {
            dispatch(register(name, email, username, password))
        }
    }

    async function completeRequest() {
        if (userInfo.address == null) {
            await delay(3000)
            nav(`/Welcome/${userInfo.email}`)
        }
        else {
            toast.success("Sign Up Successful!")
            await delay(3000)
            nav('/')
        }

    }

    return (
        <div>
            <div className="authincation section-padding">
                <div className="container h-100">
                    <div className="row justify-content-center h-100 align-items-center">
                        <div className="col-xl-5 col-md-6">
                            <div className="mini-logo text-center my-4">
                                <img className="signin-logo" src={logo} alt="" />
                                <h4 className="card-title mt-5">Create your account</h4>
                            </div>
                            <div className="auth-form card">
                                <div className="card-body">
                                    {message && <h5 className="text-brand-2 mt-30">{message}</h5>}
                                    {error && <h5 className="text-brand-2 mt-30">{error}</h5>}

                                    <form onSubmit={submitHandler} name="myform" className="signin_validate row g-3">
                                        <div className="col-12">
                                            <label className="form-label">Full Name</label>
                                            <input type="text" className="form-control" value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                name="name" />
                                        </div>
                                        <div className="col-12 ">
                                            <label className="form-label">Email</label>
                                            <input type="email" className="form-control" value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                name="email" />
                                        </div>
                                        <div className="col-12 ">
                                            <label className="form-label">Username</label>
                                            <input type="text" className="form-control" value={username}
                                                onChange={(e) => setUsername(e.target.value)}
                                                name="username" />
                                        </div>
                                        <div className="col-12">
                                            <label className="form-label">Password</label>
                                            <input type="password" className="form-control" value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                placeholder="************" name="password" />
                                        </div>
                                        <div className="col-12">
                                            <label className="form-label">Confirm Password</label>
                                            <input type="password" className="form-control" value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                placeholder="************" name="password" />
                                        </div>
                                        <div className="col-12">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                                                    I agree to the <a href="#" className="text-primary">User Agreement</a> and <a href="#" className="text-primary">Privacy Policy</a>.
                                                </label>
                                            </div>
                                        </div>

                                        <div className="d-grid gap-2">
                                            {loading ? (<h5 className="text-brand-2 mt-30"><Loading /></h5>)
                                                : (
                                                    <button type="submit" className="btn btn-primary">Create Account</button>
                                                )}
                                        </div>
                                    </form>
                                    <div className="text-center">
                                        <p className="mt-3 mb-0"> <a className="text-primary" href="/signin">Sign in</a> to your
                                            account</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUp