import React from 'react'
import './BuyCrypto.css'
import logo from "../images/6.png"
import transak from "../images/transakLogo.png"
import { useNavigate } from 'react-router-dom'

import transakSDK from "@transak/transak-sdk";

import { useContext } from "react";
import { TransactionContext } from '../context/TransactionContext'

const BuyCrypto = () => {
    const { currentAccount } = useContext(TransactionContext);
    const nav = useNavigate();

    const cancelHandler = (e) => {
        e.preventDefault()
        nav('/')
    }

    const settings = {
        apiKey: '1447a679-2273-49e3-9cdd-4f61e6802586',  // Your API Key
        environment: 'STAGING', // STAGING/PRODUCTION
        defaultCryptoCurrency: 'ETH',
        walletAddress: currentAccount,
        fiatCurrency: 'ZAR',
        themeColor: '000000', // App theme color
        hostURL: window.location.origin,
        widgetHeight: "700px",
        widgetWidth: "500px",
    }
    
    function openTransak() {
        //console.log(settings)
        const transak = new transakSDK(settings);
    
        transak.init();
    
        // To get all the events
        transak.on(transak.ALL_EVENTS, (data) => {
            console.log(data)
        });
    
        // This will trigger when the user closed the widget
        transak.on(transak.EVENTS.TRANSAK_WIDGET_CLOSE, (eventData) => {
            console.log(eventData);
            transak.close();
        });
    
        // This will trigger when the user marks payment is made.
        transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData) => {
            console.log(orderData);
            window.alert("Payment Success")
            transak.close();
        });
    }

    return (
        <div className='bodyBuyCrypto'>
            <main className='mainbodyBuyCrypto'>
                <div className="cardBuyCrypto">
                    <div className="card-header">
                        <img src={logo} alt="" />
                    </div>
                    <div className="card-body">
                        <div className="card-title">Proceed to purchase</div>
                        <div className="card-text">All crypto purchases are managed by our trusted and internationally acclaimed partner<br /><b><a href="https://transak.com/">Transak</a></b></div>
                        <div className="card-plan">
                            <a href="https://transak.com/"><img style={{width: `100%`}} src={transak} alt="" /></a>
                        </div>
                        <div className="card-payment-button">
                            <button onClick={() => openTransak()}>Proceed to Payment</button>
                        </div>
                        <div className="card-cancel-button">
                            <button onClick={cancelHandler}>Return Home</button>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default BuyCrypto