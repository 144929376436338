import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
import { listUsers, addUserContact } from '../actions/userActions'
import moment from 'moment'

const Contacts = () => {
    const nav = useNavigate()
    const dispatch = useDispatch()

    const userList = useSelector((state) => state.userList)
    const { loading, error, users } = userList

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    useEffect(() => {
        if (userInfo) {
            dispatch(listUsers())
        } else {
            nav('/signin')
        }
    }, [dispatch, userInfo])

    const submitHandler = (id) => {
        //e.preventDefault()
        //console.log(e.target.value)
        console.log(id)
        dispatch(addUserContact(id))
    }

    return (
        <div>
            <div id="main-wrapper" className="show">
                <div className="content-body" style={{ minHeight: `979px` }}>
                    <div className="container">
                        <div className="page-title">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-xl-4">
                                    <div className="page-title-content">
                                        <h3>Contacts</h3>
                                        <p className="mb-2">View your contact list</p>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="breadcrumbs"><a href="#">Home </a><span><i className="ri-arrow-right-s-line"></i></span><a href="#">Contacts</a></div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-3 col-sm-6">
                                <div className="stat-widget d-flex align-items-center bg-white">
                                    <div className="widget-icon me-3 bg-primary"><span><i className="ri-file-copy-2-line"></i></span></div>
                                    <div className="widget-content">
                                        <h3>{users?.length}</h3>
                                        <p>Total Contacts</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-xl-3 col-sm-6">
                                <div className="stat-widget d-flex align-items-center bg-white">
                                    <div className="widget-icon me-3 bg-success"><span><i className="ri-file-list-3-line"></i></span></div>
                                    <div className="widget-content">
                                        <h3>273</h3>
                                        <p>Paid Invoices</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6">
                                <div className="stat-widget d-flex align-items-center bg-white">
                                    <div className="widget-icon me-3 bg-warning"><span><i className="ri-file-paper-line"></i></span></div>
                                    <div className="widget-content">
                                        <h3>121</h3>
                                        <p>Received Invoices</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6">
                                <div className="stat-widget d-flex align-items-center bg-white">
                                    <div className="widget-icon me-3 bg-danger"><span><i className="ri-file-paper-2-line"></i></span></div>
                                    <div className="widget-content">
                                        <h3>89</h3>
                                        <p>Pending Invoices</p>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-header flex-row">
                                        <h4 className="card-title">Contacts </h4>
                                        <a className="btn btn-primary" href="/send"><span><i className="bi bi-plus"></i></span>Send</a>
                                    </div>
                                    <div className="card-body">
                                        <div className="invoice-table">
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Client</th>
                                                            <th>Joined</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {users?.map((user) => (
                                                            <tr key={user._id}>
                                                                <td><img src={user.image} alt="" width="30" className="me-2" />{user.name}</td>
                                                                <td>{moment(user.createdAt, "YYYYMMDD").fromNow()}</td>
                                                                <td><button className="btn btn-primary" onClick={() => submitHandler(user._id)}><span><i className="bi bi-plus"></i></span>Add</button></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Contacts