import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { Toaster } from 'react-hot-toast';
import { Outlet, Link } from "react-router-dom";
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import AppLogout from '../utils/AppLogout';
import { useNavigate } from 'react-router-dom';

const Layout = () => {

    const nav = useNavigate()

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    useEffect(() => {
        if (!userInfo) {
            nav("/SignIn")
        } else {
        }
    })

    return (
        <div>
            <AppLogout>
                <Header />
                <Sidebar />
                <Outlet />
            </AppLogout>
        </div>
    )
}

export default Layout