import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { forgot } from '../actions/userActions'
import logo from '../images/6.png'
import Loading from '../components/Loading';

const ForgotPassword = () => {
    const [email, setEmail] = useState('')

    const dispatch = useDispatch()
    const nav = useNavigate();

    const userForgot = useSelector((state) => state.userForgot)
    const { loading, user, success, error } = userForgot

    useEffect(() => {
        if (success) {
            nav(`/VerificationEmailSent/${email}`)
        }
        // eslint-disable-next-line
    }, [success])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(forgot(email))
    }

    return (
        <div>
            <div id="main-wrapper" className="show">

                <div className="authincation section-padding">
                    <div className="container h-100">
                        <div className="row justify-content-center h-100 align-items-center">
                            <div className="col-xl-4 col-md-5">
                                <div className="mini-logo text-center my-3">
                                    <img className="signin-logo" src={logo} alt="" />
                                    <h4 className="card-title mt-5">Forgot Password</h4>
                                </div>
                                <div className="auth-form card">
                                    <div className="card-body">
                                        {error && <h5 className="text-brand-2 mt-30">{error}</h5>}
                                        {loading && <h5 className="text-brand-2 mt-30"><Loading /></h5>}
                                        <form onSubmit={submitHandler} className="row g-3">
                                            <div className="col-12">
                                                <label className="form-label">Email</label>

                                                <input type="text" className="form-control" placeholder='user@email.com' onChange={(e) => setEmail(e.target.value)} />
                                            </div>
                                            <div className="text-center mt-4">
                                                <button type="submit" className="btn btn-primary btn-block">Submit</button>
                                            </div>
                                        </form>
                                        {/* <div className="new-account mt-3">
                                            <p>Don't get code? <a className="text-primary" href="otp-1.html">Resend</a></p>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ForgotPassword