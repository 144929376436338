import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { reset } from '../actions/userActions'
import logo from '../images/6.png'
import Loading from '../components/Loading';

const ResetPassword = () => {
    const params = useParams();
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage] = useState('')

    const dispatch = useDispatch()
    const nav = useNavigate();

    const userReset = useSelector((state) => state.userReset)
    const { loading, user, success, error } = userReset

    useEffect(() => {
        if (success) {
            nav('/signin')
        }
        // eslint-disable-next-line
    }, [history, success])

    const submitHandler = (e) => {
        e.preventDefault()
        if (password !== confirmPassword) {
            setMessage('Passwords do not match')
        } else {
            dispatch(reset(password, params.id, params.token))
            //dispatch(reset(name, email, username, password))
        }
    }

    return (
        <div>
            <div id="main-wrapper" className="show">

                <div className="authincation section-padding">
                    <div className="container h-100">
                        <div className="row justify-content-center h-100 align-items-center">
                            <div className="col-xl-4 col-md-5">
                                <div className="mini-logo text-center my-3">
                                    <img className="signin-logo" src={logo} alt="" />
                                    <h4 className="card-title mt-5">Forgot Password</h4>
                                </div>
                                <div className="auth-form card">
                                    <div className="card-body">
                                        {message && <h5 className="text-brand-2 mt-30">{message}</h5>}
                                        {error && <h5 className="text-brand-2 mt-30">{error}</h5>}
                                        {loading && <h5 className="text-brand-2 mt-30"><Loading /></h5>}
                                        <form onSubmit={submitHandler} className="row g-3">
                                            <div className="col-12">
                                                <label className="form-label">New Password</label>

                                                <input type="password" className="form-control" onChange={(e) => setPassword(e.target.value)} />

                                                <label className="form-label" style={{ marginTop: `15px` }}>Confirm Password</label>

                                                <input type="password" className="form-control" onChange={(e) => setConfirmPassword(e.target.value)} />
                                            </div>
                                            <div className="text-center mt-4">
                                                <button type="submit" className="btn btn-primary btn-block">Submit</button>
                                            </div>
                                        </form>
                                        {/* <div className="new-account mt-3">
                                    <p>Don't get code? <a className="text-primary" href="otp-1.html">Resend</a></p>
                                </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ResetPassword