import React, { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import moment from 'moment'
import { useContext } from "react";
import { TransactionContext } from '../context/TransactionContext';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import BalanceDetails from '../components/BalanceDetails';
import TransactionDetails from '../components/TransactionDetails';

const Balances = () => {
    const { currentAccount, connectWallet, isLoading, balance, transactions } = useContext(TransactionContext);

    const nav = useNavigate();
    const dispatch = useDispatch()

    const userLogin = useSelector((state) => state.userLogin)
    const { loading, error, userInfo } = userLogin

    //const redirect = location.search ? location.search.split('=')[1] : '/'

    useEffect(() => {
        if (!userInfo) {
            nav("/SignIn");
        }
    }, [userInfo])


    return (
        <div>
            <div id="main-wrapper" className="show"><div className="content-body">
                <div className="container">
                    <div className="page-title">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-xl-4">
                                <div className="page-title-content"><h3>Balances</h3></div></div>
                            <div className="col-auto"><div className="breadcrumbs"><a href="/">Home</a><span><i className="ri-arrow-right-s-line"></i></span><a href="#">Balances</a>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center h-100 align-items-center"><div>
                        <div className="row" style={{ margin: `auto` }}>
                            <BalanceDetails/>
                            <TransactionDetails/>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Balances