import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
import { getUserDetails, updateUserProfile } from '../actions/userActions'
import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants'
import axios from 'axios'
import { useContext } from "react";
import { TransactionContext } from '../context/TransactionContext';
import toast, { Toaster } from 'react-hot-toast'
import delay from '../utils/delay';

const Settings = () => {
    const [name, setName] = useState('')
    const [image, setImage] = useState('')
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [zarAddress, setZarAddress] = useState('')
    const [contactNumber, setContactNumber] = useState('')
    const [province, setProvince] = useState('')
    const [city, setCity] = useState('')
    const [zipcode, setZipCode] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage] = useState(null)
    const [uploading, setUploading] = useState(false)

    const { currentAccount } = useContext(TransactionContext);

    const userDetails = useSelector((state) => state.userDetails)
    const { loading, error, user } = userDetails

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
    const { success } = userUpdateProfile

    const nav = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        if (!userInfo) {
            nav("/SignIn")
        } else {
            if (!user || !user.name || success) {
                dispatch({ type: USER_UPDATE_PROFILE_RESET })
                dispatch(getUserDetails('profile'))

                //completeRequest()

            } else {
                setName(user.name)
                setImage(user.image)
                setUsername(user.username)
                setEmail(user.email)
                setZarAddress(user.address)
                setContactNumber(user.contactNumber)
                setProvince(user.province)
                setCity(user.city)
                setZipCode(user.zipcode)
            }
        }
    }, [dispatch, userInfo, user, success])

    const uploadFileHandler = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('image', file)
        setUploading(true)

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }

            const { data } = await axios.post('/api/upload', formData, config)

            setImage(data)
            setUploading(false)
        } catch (error) {
            console.error(error)
            setUploading(false)
        }
    }

    const submitHandler = (e) => {
        e.preventDefault()
        if (password !== confirmPassword) {
            setMessage('Passwords do not match')
        } else {
            dispatch(updateUserProfile({ id: user._id, name, username, email, city }))
        }
    }

    async function completeRequest() {
        toast.success("Sign In Successful")
        await delay(3000)
        nav('/')
    }

    return (
        <>
            <div id="main-wrapper" className="show">
                <div className="content-body">
                    <div className="container">
                        <div className="row active">
                            <div className="col-xl-12">
                                <div className="page-title">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-xl-4">
                                            <div className="page-title-content">
                                                <h3>Profile</h3>
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <div className="breadcrumbs"><a href="#">Settings </a><span><i className="ri-arrow-right-s-line"></i></span><a href="#">Profile</a></div>
                                        </div>
                                    </div>
                                </div>
                                {/* <ul className="settings-menu"><li className=""><a href="/settings-profile">Profile</a></li><li className=""><a href="/settings-application">Application</a></li><li className=""><a href="/settings-security">Security</a></li><li className=""><a href="/settings-activity">Activity</a></li><li className="active"><a href="/settings-payment-method">Payment Method</a></li><li className=""><a href="/settings-api">API</a></li></ul> */}
                            </div>
                            <div className="col-xxl-12 col-xl-12 show">
                                <div className="settings-menu active">
                                    {message && <h5 className="text-brand-2 mt-30">{message}</h5>}
                                </div>
                                <div className="row">
                                    <div className="col-xxl-6 col-xl-6 col-lg-6">
                                        <div className="card">
                                            <div className="card-header">
                                                <h4 className="card-title">User Profile</h4>
                                            </div>
                                            <div className="card-body">
                                                <form onSubmit={submitHandler}>
                                                    <div className="row g-3">
                                                        <div className="col-xxl-12 col-12 mb-3">
                                                            <label className="form-label">Full Name</label>
                                                            <input type="text" className="form-control" value={name}
                                                                onChange={(e) => setName(e.target.value)} />
                                                        </div>
                                                        <div className="col-xxl-12 col-12 mb-3">
                                                            <div className="d-flex align-items-center">
                                                                <img className="me-3 rounded-circle me-0 me-sm-3" src={"https://intez-html.vercel.app/images/profile/3.png"} width="55" height="55" alt="" />
                                                                <div className="media-body">
                                                                    <h4 className="mb-0">{name}</h4>
                                                                    <p className="mb-0">Max file size is 20mb
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-12 col-12 mb-3">
                                                            <div className="form-file">
                                                                <input type="file" className="form-file-input" onChange={uploadFileHandler} id="customFile" />
                                                                <label className="form-file-label" htmlFor="customFile">
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-12 col-12 mb-3">
                                                            <button type="submit" className="btn btn-primary">Save</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-6 col-xl-6 col-lg-6">
                                        <div className="card">
                                            <div className="card-header">
                                                <h4 className="card-title">User Profile</h4>
                                            </div>
                                            <div className="card-body">
                                                <form onSubmit={submitHandler}>
                                                    <div className="row g-3">
                                                        <div className="col-12 mb-3">
                                                            <label className="form-label">Username</label>
                                                            <input type="text" className="form-control" value={username}
                                                                onChange={(e) => setUsername(e.target.value)} />
                                                        </div>
                                                        <div className="col-12 mb-3">
                                                            <label className="form-label">Wallet address</label>
                                                            <input type="text" disabled className="form-control" value={zarAddress}
                                                                onChange={(e) => setZarAddress(e.target.value)} />
                                                            {/* <small className="mt-2 mb-0 d-block">Enable two factor authencation
                                                                on the
                                                                security
                                                                page
                                                            </small> */}
                                                        </div>
                                                        <div className="col-12 col-12 mb-3">
                                                            <button type="submit" className="btn btn-primary">Save</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <h4 className="card-title">Personal Information</h4>
                                            </div>
                                            <div className="card-body">
                                                <form onSubmit={submitHandler}>
                                                    <div className="row g-4">
                                                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                                                            <label className="form-label">Full Name</label>
                                                            <input type="text" className="form-control" name="name" value={user?.name}
                                                                onChange={(e) => setName(e.target.value)} />
                                                        </div>
                                                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                                                            <label className="form-label">Email</label>
                                                            <input type="email" className="form-control" name="email" value={user?.email}
                                                                onChange={(e) => setEmail(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                                                            <label className="form-label">Address</label>
                                                            <input type="text" className="form-control" placeholder="123, Auckland Park" name="permanentaddress" />
                                                        </div>
                                                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                                                            <label className="form-label">City</label>
                                                            <input type="text" className="form-control" placeholder="Johannesburg" name="city" value={user?.city}
                                                                onChange={(e) => setCity(e.target.value)} />
                                                        </div>
                                                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                                                            <label className="form-label">Postal Code</label>
                                                            <input type="text" className="form-control" placeholder="2005" name="postal" />
                                                        </div>
                                                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                                                            <label className="form-label">Country</label>
                                                            <select className="form-select" name="country">
                                                                <option value="">Select</option>
                                                                <option value="Algeria">Algeria</option>
                                                                <option value="Angola">Angola</option>
                                                                <option value="Botswana">Botswana</option>
                                                                <option value="Burkina Faso">Burkina Faso</option>
                                                                <option value="Burundi">Burundi</option>
                                                                <option value="Cameroon">Cameroon</option>
                                                                <option value="Cape Verde">Cape Verde</option>
                                                                <option value="Central African Republic">Central African
                                                                    Republic</option>
                                                                <option value="Congo">Congo</option>
                                                                <option value="Congo, The Democratic Republic of The">
                                                                    Congo, The
                                                                    Democratic Republic of The</option>
                                                                <option value="Cote D'ivoire">Cote D'ivoire</option>
                                                                <option value="Egypt">Egypt</option>
                                                                <option value="Ethiopia">Ethiopia</option>
                                                                <option value="Ghana">Ghana</option>
                                                                <option value="Kenya">Kenya</option>
                                                                <option value="Lesotho">Lesotho</option>
                                                                <option value="Malawi">Malawi</option>
                                                                <option value="Mauritius">Mauritius</option>
                                                                <option value="Mozambique">Mozambique</option>
                                                                <option value="Namibia">Namibia</option>
                                                                <option value="Nigeria">Nigeria</option>
                                                                <option value="South Africa">South Africa</option>

                                                                <option value="Swaziland">Swaziland</option>
                                                                <option value="Zimbabwe">Zimbabwe
                                                                </option>
                                                            </select>
                                                        </div>

                                                        <div className="col-12">
                                                            <button type="submit" className="btn btn-primary pl-5 pr-5">Save</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Settings