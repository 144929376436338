import React, { useEffect, useState } from "react";
import { ethers, Wallet } from "ethers";
import axios from 'axios';
import TokenArtifact from "../utils/Token.json";
import contractAddress from "../utils/contract-address.json";

import { useSelector } from "react-redux";
import CryptoConvert from "crypto-convert";

export const TransactionContext = React.createContext();

const convert = new CryptoConvert({
  cryptoInterval: 15000, //Crypto prices update interval in ms (default 5 seconds on Node.js & 15 seconds on Browsers)
  fiatInterval: (60 * 1e3 * 60), //Fiat prices update interval (default every 1 hour)
  calculateAverage: true, //Calculate the average crypto price from exchanges
  binance: true, //Use binance rates
  HTTPAgent: null //HTTP Agent for server-side proxies (Node.js only)
});

export const TransactionsProvider = ({ children }) => {
  const [etherUrl, setEtherUrl] = useState()
  //const [formData, setformData] = useState({ addressTo: "", amount: "", message: "" });
  const [addressTo, setAddressTo] = useState('')
  const [amount, setAmount] = useState(0)
  const [message, setMessage] = useState('')
  const [currentAccount, setCurrentAccount] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [transactionCount, setTransactionCount] = useState(localStorage.getItem("transactionCount"));
  const [transactions, setTransactions] = useState([]);
  const [tokenData, setTokenData] = useState({ name: '', symbol: '' })
  const [balance, setBalance] = useState('')

  const userLogin = useSelector((state) => state.userLogin)
  const { success, loading, error, userInfo } = userLogin

  const getTransactions = () => {
    const address = JSON.parse(localStorage.getItem('userInfo'))

    var config = {
      method: 'get',
      url: 'https://api.bscscan.com/api?module=account&action=txlist&address=' + address.address + '&startblock=0&endblock=99999999&page=1&offset=1000&sort=desc&apikey=9QGZKJDC8PH9J245N99X8FFA6CQBYRDPEP',
      headers: {}
    };

    axios(config)
      .then(function (response) {
        response.data.result.forEach(convertCurrencyValue)
        setTransactions(response.data.result)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function convertCurrencyValue(item, index, arr) {
    arr[index].value = (ethers.utils.formatEther(item.value) * convert.BNB.ZAR(1)).toFixed(2)
  }



  const updateBalance = async () => {
    const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed.binance.org');
    const address = JSON.parse(localStorage.getItem('userInfo'))

    const balance = await provider.getBalance(address.address);

    await convert.ready();

    const amountConverted = convert.BNB.ZAR(1)
    //const amountConverted = await BNBToZar(balance)

    //console.log(ethers.utils.formatEther(balance))
    setBalance((ethers.utils.formatEther(balance) * amountConverted).toFixed(2))
  }

  useEffect(() => {
    const address = JSON.parse(localStorage.getItem('userInfo'))
    //console.log(address)
    if (address?.address) {
      setCurrentAccount(address.address)
      updateBalance();
      getTransactions();
    }
  }, [userInfo, balance]);

  return (
    <TransactionContext.Provider
      value={{
        transactionCount,
        currentAccount,
        isLoading,
        addressTo,
        setAddressTo,
        amount,
        setAmount,
        message,
        setMessage,
        updateBalance,
        balance,
        transactions
      }}
    >
      {children}
    </TransactionContext.Provider>
  );
};