import React, { useState, useEffect } from 'react'
import accountOne from '../images/1.png'
import accountTwo from '../images/2.png'
import moment from 'moment'
import BalanceDetails from '../components/BalanceDetails';
import TransactionDetails from '../components/TransactionDetails';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { ethers } from 'ethers';

import { useContext } from "react";
import { TransactionContext } from '../context/TransactionContext'

import toast from 'react-hot-toast'
import delay from '../utils/delay';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
        }
    }
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

const chartData = Math.floor(Math.random() * labels.length)

export const data = {
    labels,
    datasets: [
        {
            fill: true,
            label: "None",
            // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            data: [500, 900, 1700, 1200, 900, 2100, 1800],
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
    ],
};

const { ethereum } = window;

const Dashboard = () => {
    const { currentAccount, connectWallet, isLoading, balance, transactions } = useContext(TransactionContext);

    const nav = useNavigate();

    const userLogin = useSelector((state) => state.userLogin)
    const { loading, error, userInfo } = userLogin

    useEffect(() => {
        if (!userInfo) {
            nav("/SignIn")
        } else {
        }
    }, [])

   

    return (
        <div><div id="main-wrapper" className="show">


            <div className="content-body">
                <div className="container">
                    <div className="page-title">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-xl-4">
                                <div className="page-title-content">
                                    <h3>Wallet</h3>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="breadcrumbs">
                                    <a href="#">
                                        Dashboard
                                    </a>
                                    <span><i className="ri-arrow-right-s-line"></i></span><a href="#">Wallet</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <BalanceDetails />
                        <TransactionDetails />
                    </div>
                    {/* <div className="row">

                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                            <div id="user-activity" className="card" data-aos="fade-up">
                                <div className="card-header">
                                    <h4 className="card-title">Transaction Overview</h4>
                                </div>
                                <div className="card-body">
                                    <Line options={options} data={data} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="credit-card visa">
                                        <div className="type-brand">
                                            <h4>Account 1</h4>
                                            <img src={accountOne} alt="" />
                                        </div>
                                        <div className="cc-number">
                                            <h6>1234</h6>
                                            <h6>5678</h6>
                                            <h6>7890</h6>
                                            <h6>9875</h6>
                                        </div>
                                        <div className="cc-holder-exp">
                                            <h5>Lesego Sambo</h5>
                                            <div className="exp"><span>EXP:</span><strong>12/21</strong></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12">
                                    <div className="credit-card payoneer">
                                        <div className="type-brand">
                                            <h4>Account 2</h4>
                                            <img src={accountTwo} alt="" />
                                        </div>
                                        <div className="cc-number">
                                            <h6>1234</h6>
                                            <h6>5678</h6>
                                            <h6>7890</h6>
                                            <h6>9875</h6>
                                        </div>
                                        <div className="cc-holder-exp">
                                            <h5>Lesego Sambo</h5>
                                            <div className="exp"><span>EXP:</span><strong>12/21</strong></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div></div>
    )
}

export default Dashboard