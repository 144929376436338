import React from 'react'
import { ColorRing } from 'react-loader-spinner'

const Loading = () => {
    return (
        <div className="row justify-content-center h-100 align-items-center">
            <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={['#0f9d75', '#0f9d98', '#0f969d', '#0f8a9d', '#0f7f9d']}
            />
        </div>
    )
}

export default Loading